import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);

// utilities routing
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);
const AddProduct = Loadable(lazy(() => import("views/Products/CreateProduct")));
const Master = Loadable(lazy(() => import("views/master/Master.js")));

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <DashboardDefault />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "product",
      element: <AddProduct />,
    },
    {
      path: "master",
      element: <Master />,
    },
    // {
    //     path: 'reports',
    //     // element: <GstReports />,
    //     children: [
    //         {
    //             path: 'gst',
    //             element: <GstReports />
    //         },
    //         {
    //             path: 'vat',
    //             element: <VatReports />
    //         },
    //         {
    //             path: 'SalesReport',
    //             element: <SalesReport />
    //         },
    //         {
    //             path: 'SalesReturnReport',
    //             element: <SalesReturnReport />
    //         },
    //         {
    //             path: 'PurchaseReport',
    //             element: <PurchaseReport />
    //         },
    //         {
    //             path: 'Stock',
    //             element: <StockReport />
    //         }
    //     ]
    // },

    {
      path: "utils",
      children: [
        {
          path: "util-color",
          element: <UtilsColor />,
        },
      ],
    },
    {
      path: "utils",
      children: [
        {
          path: "util-shadow",
          element: <UtilsShadow />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "tabler-icons",
          element: <UtilsTablerIcons />,
        },
      ],
    },
    {
      path: "icons",
      children: [
        {
          path: "material-icons",
          element: <UtilsMaterialIcons />,
        },
      ],
    },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
  ],
};

export default MainRoutes;
